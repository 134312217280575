import React from "react"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"

const DemoPage = () => {
  const body = [
    {
      component: "hero_block",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
      kicker: "Der Kicker",
      headline_level: "h1",
      headline: "Die Headline",
      subheadline: "Die Subheadline",
      buttons: [
        { link: "/demo", target: "", link_text: "Ein 1. Button" },
        { link: "/demo", target: "", link_text: "Ein 2. Button" },
      ],
    },

    {
      component: "logo_grid_block",
      headline: "Unsere Kunden",
      logo_items: [
        {
          link: "/",
          image: {
            filename:
              "https://media.eintracht.de/image/upload/v1583829110/deutsche-boerse-group_black-d81c.svg",
          },
          logo_size: "big",
        },
        {
          link: "/",
          image: {
            filename:
              "https://media.eintracht.de/image/upload/v1583829167/lotto-hessen_black-8c88.svg",
          },
          logo_size: "normal",
        },
        {
          link: "/",
          image: {
            filename:
              "https://upload.wikimedia.org/wikipedia/commons/9/94/AXA_Logo.svg",
          },
          logo_size: "small",
        },
        {
          link: "/",
          image: {
            filename:
              "https://upload.wikimedia.org/wikipedia/commons/b/bd/Tesla_Motors.svg",
          },
          logo_size: "small",
        },
      ],
    },

    {
      component: "cards_block",
      headline: "Cards Block",
      card_items: [
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
      ],
    },
    {
      component: "cards_block",
      headline: "Cards Block",
      view: "slider",
      card_items: [
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
        {
          image: {
            filename: "https://picsum.photos/1920/1080",
          },
          kicker: "Literatur",
          headline: "Eine wunderbare Heiterkeit",
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
          link: "/demo",
          link_text: "Mehr erfahren",
        },
      ],
    },
    {
      component: "faq_block",
      headline: "FAQ",
      faq_items: [
        {
          title: "Ist dies nun die 1. Frage?",
          text: "Eine wunderbare **Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. ",
        },
        {
          title: "Ist dies dann die 2. Frage?",
          text: "Eine wunderbare _Heiterkeit hat_ meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. ",
        },
        {
          title: "Ist dies bereits die 3. Frage?",
          text: "Eine wunderbare _Heiterkeit hat_ meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. ",
        },
      ],
    },

    {
      component: "stats_block",
      kicker: "Literatur",
      headline: "Wichtige Zahlen zu uns",
      stat_items: [
        {
          number: "24",
          unit: "MP",
          title: "Fotos",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
        {
          number: "2,1",
          unit: "Millionen",
          title: "Pixel hat 1920x1080",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
        {
          number: "1280",
          unit: "px",
          title: "Maximal Breite",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
      ],
    },

    {
      component: "cta_block",
      text: "Eine wunderbare Heiterkeit hat **meine ganze Seele** eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.",
      buttons: [
        { link: "/demo", target: "", link_text: "Ein 1. Button" },
        { link: "/demo", target: "", link_text: "Ein 2. Button" },
      ],
    },

    {
      component: "team_block",
      headline: "Unser Team",
      team_items: [
        {
          image: {
            filename: "https://picsum.photos/1080/1080",
          },
          name: "Max Mustermann",
          description: "Muster-Position",
          tel: "0123/45678910",
          email: "mustermann@muster.de",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
        {
          image: {
            filename: "https://picsum.photos/1080/1080",
          },
          name: "Max Mustermann",
          description: "Muster-Position",
          tel: "0123/45678910",
          email: "mustermann@muster.de",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
        {
          image: {
            filename: "https://picsum.photos/1080/1080",
          },
          name: "Max Mustermann",
          description: "Muster-Position",
          tel: "0123/45678910",
          email: "mustermann@muster.de",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
      ],
    },

    {
      component: "features_block",
      headline: "Wichtige Features:",
      feature_items: [
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-info",
          },
          title: "Informationen",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-envelope",
          },
          title: "Informationen",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-info",
          },
          title: "Informationen",
          text: "**Eine wunderbare Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
        },
      ],
    },

    {
      component: "quote_block",
      image: {
        filename: "https://picsum.photos/1080/1080",
      },
      quote:
        "Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet.",
      author: "Der junge Werther",
      author_description: "Hauptfigur der Geschichte",
    },
    {
      component: "quote_block",
      quote:
        "Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet.",
      author: "Der junge Werther",
      author_description: "Hauptfigur der Geschichte",
    },

    {
      component: "text_block",
      kicker: "Der Kicker",
      headline_level: "h2",
      headline: "Die Headline",
      subheadline: "Die Subheadline",
      buttons: [
        { link: "/demo", target: "", link_text: "Ein 1. Button" },
        { link: "/demo", target: "", link_text: "Ein 2. Button" },
      ],
      text: "Eine wunderbare Heiterkeit hat **meine ganze Seele** eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin *so glücklich*, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß [meine Kunst](https://www.google.de) darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
    },
    {
      component: "text_block",
      kicker: "Der Kicker",
      headline_level: "h2",
      headline: "Die Headline",
      subheadline: "Die Subheadline",
      flow: "true",
      text: "Eine wunderbare Heiterkeit hat **meine ganze Seele** eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin *so glücklich*, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß [meine Kunst](https://www.google.de) darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
    },

    {
      component: "text_block",
      kicker: "Der Kicker",
      headline_level: "h2",
      headline: "Die Headline",
      subheadline: "Die Subheadline",
      text_position: "left",
      text: "Eine wunderbare Heiterkeit hat **meine ganze Seele** eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin *so glücklich*, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß [meine Kunst](https://www.google.de) darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
      caption: "Dies ist ein zufälliges Bild",
    },
    {
      component: "text_block",
      kicker: "Der Kicker",
      headline_level: "h2",
      headline: "Die Headline",
      subheadline: "Die Subheadline",
      text_position: "left",
      text: "Eine wunderbare Heiterkeit hat **meine ganze Seele** eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin *so glücklich*, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß [meine Kunst](https://www.google.de) darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.",
      _uid: "072929",
      video_url: "https://www.youtube.com/watch?v=OplU67FMw5U",
      ratio: "16by9",
      caption: "Ein Musikvideo als Test für die Video-Einbindung",
    },
    {
      component: "video_block",
      _uid: "072928",
      video_url: "https://www.youtube.com/watch?v=OplU67FMw5U",
      ratio: "16by9",
      caption: "Ein Musikvideo als Test für die Video-Einbindung",
    },
    {
      component: "embed_block",
      embed_code:
        '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d163720.8725399987!2d8.496481234885447!3d50.121127742312346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd096f477096c5%3A0x422435029b0c600!2sFrankfurt%20am%20Main!5e0!3m2!1sde!2sde!4v1600262389997!5m2!1sde!2sde" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
      ratio: "16by9",
    },
    {
      component: "gallery_block",
      headline: "Beispiel-Gallery",
      view: "slider",
      image_items: [
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 1. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 2. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 3. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 4. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 5. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 6. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 7. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 8. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 9. Bild",
        },
      ],
    },
    {
      component: "gallery_block",
      headline: "Beispiel-Gallery",
      view: "thumbnails",
      image_items: [
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 1. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 2. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 3. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 4. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 5. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 6. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/800" },
          caption: "Bild-Beschreibung vom 7. Bild",
        },
        {
          image: { filename: "https://picsum.photos/800/1200" },
          caption: "Bild-Beschreibung vom 8. Bild",
        },
        {
          image: { filename: "https://picsum.photos/1200/1200" },
          caption: "Bild-Beschreibung vom 9. Bild",
        },
      ],
    },
    {
      component: "image_block",
      block_width: "content",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
      caption: "Dies ist ein zufälliges Bild",
    },
    {
      component: "image_block",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
      caption: "Dies ist ein zufälliges Bild",
    },
    {
      component: "image_block",
      block_width: "screen",
      image: {
        filename: "https://picsum.photos/1920/1080",
      },
      caption: "Dies ist ein zufälliges Bild",
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoPage
